// GETリクエストでjavascriptファイルを読み込みコールバック関数で実行
//$.getScript("../js/jquery.jcimodal.js"); //modalのjsを読み込み

// GTM(GoogleTagManager)のカスタムイベント記録用
const gtmDL = (event, eventCategory, eventAction, eventLabel, eventValue = 1) => {
  // eventValue以外の引数どれかがundefだったら早期リターン
  const isUndefAny = [event, eventCategory, eventAction, eventLabel].some(v => v === undefined);
  if (isUndefAny) return false;
  //eventValue = (typeof eventValue === 'undefined') ? 1: eventValue;
  dataLayer.push({ event, eventCategory, eventAction, eventLabel, eventValue, });
};

//スマホ幅だったらtrue返却 20200615TM
let isSP = (window.innerWidth < 768); // ブラウザサイズ変更時に再セットするためlet

//iOS判定　iOSだったらtrue返却 20180620TM
const isiOS = () => {
  const ua = window.navigator.userAgent;
  const iOS_flag = /iPhone|iPad|iPod/.test(ua);
  const iPad_flag = ua.indexOf('iPad') > -1 || ua.indexOf('Macintosh') > -1 && 'ontouchend' in document; //2019年9月のiPadOS13から判定を加える
  return iOS_flag || iPad_flag;
};

//音声オプション マニュアル用 →を見つけてアイコンspanタグに置換
const replace_txtArrow = () => {
  $(".icons:contains('→') ").each((i, elem) => elem.innerHTML = elem.innerHTML.replace(/→/g,'<span class="font_arrow"></span>'));
};

// 数値にカンマを付ける
const putComma = (num) => {
  return String(num).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

// 外部スクリプト(URL)を閉じBODYタグの上の行に追加
const appendScript = (URL) => {
  const el = document.createElement('script');
  el.src = URL;
  document.body.appendChild(el);
};

// カレントURLをアクティブに&アクティブのタブまで自動スライド
const currentAutoSlide = () => {
  if ($(".naviContent li a").length != 0) {
    const url = window.location.href.split('/').pop();
    url = (url == "") ? "index.html" : url;
    url = (url.indexOf('s_') === 0) ? "support.html" : url;
    const current_tab = $('.naviContent li a[href*= "' + url + '"]');

    if (current_tab.length === 0) {
      $(".naviContent li:first-child a").addClass("product_current");
    } else {
      current_tab.addClass("product_current");
    }

    const slidable_navi = $('div.naviContent');//スライドするオブジェクト
    const slide_length = $(".naviContent li a[class*='product_current']").offset();//スライドする長さはcurrentタブの左まで
    if (slide_length !== undefined) {
      slidable_navi.animate({scrollLeft: slide_length.left});
    }
  }
};

//引数の文字列含むならtrue返却 20180710TM
//const searchStr = (searchstr, targetstr) => {
//  const searchRegEx = new RegExp(searchstr);
//  return (searchRegEx.test(targetstr));
//}

// 消費税計算・incは渡す金額が税別なら指定しない。inc = 'in'なら税込のまま渡す
const calcTax = (price, inc = 'out') => {
  const taxrate = 1.10;
  price = Number(String(price).replace(/,/g, ''));
  if (price === 0) return '0円';

  const [taxOut, init, taxInDecimal] = [
    [price, ],
    [price * taxrate, ],
    [price * taxrate, 1], // 少数切り捨てfrom健さん(利用規約・マイページとの齟齬のため)→少数表記できるように復活させておく(未利用)
  ].map(([prc, dight]) => dight ? putComma(prc.toFixed(dight)) : putComma(Math.floor(prc)));

  const priceFormat = {
    out: [`${init}円`, `${init} yen (incl. tax)`], //税別がデフォルト、非表示にする。書き換えるときに税率をかける
    in: [`${taxOut}円`, `${taxOut} yen (incl. tax)`], //金額がすでに税込の場合(プリペイド)
    men: [`${taxOut}円(非課税)`, `${taxOut} yen (tax-exempt)`],
    both: [`${init}円(税別${taxOut}円)`, `${init} yen (incl. tax) (excl. ${taxOut} yen)`],
    decimal: [`${taxInDecimal}円`, `${taxInDecimal} yen (incl. tax)`], //通話料など
    deunit: [`${init}`, `${init}`], //円をとる
  };
  //const index = Number(cpath === '/english');
  return priceFormat[inc][0];
};

//要素存在判定　 20180711TM
const elemExist = (selector) => {
  const ex = (selector.includes('#')) ? document.getElementById(selector.substr(1)) : document.querySelector(selector);
  return (ex !== null);
};

const URLdiv = (url = window.location.href) => { // https://qiita.com/kerupani129/items/adc0fba4ab248330e443
  const matchedFileName = url.match(/^(?:[^:\/?#]+:)?(?:\/\/[^\/?#]*)?(?:([^?#]*\/)([^\/?#]*))?(\?[^#]*)?(?:#.*)?$/) ?? [];
  const urlArray = matchedFileName.map(match => match ?? '');
  const [, dir, filename, query] = urlArray;
  return {
    dir,
    filename,
    query,
  };
};

//URLディレクトリ部分を取得
const getUrlDir = () => {
  // / → ''
  // /path → 'path'
  // /path/ → 'path'
  // /path/path2 → 'path2'
  // /path/path2/ → 'path2'
  // /path/path2.html → 'path'
  // /path/path2.php → 'path'

  // URLインターフェースはbabel効かない
  //const url = new URL(window.location.href);
  //const pathnames = url.pathname.split('/');
  //const dir = (pathnames.slice(-1)[0].includes('.')) ? pathnames[pathnames.length -2] : pathnames.slice(-1)[0];
  const dir = URLdiv().dir.match(/^\/(.+)\/$/);
  return dir[1];
};

//ファイル名を取得
const getHtmlName = () => URLdiv().filename;

//URLパラメータ部分を取得
const getUrlParam = () => (location.search.length) ? location.search + '&' : '?';

const strUrlParam = (paramObj) => {
  return Object.entries(paramObj).map(([key, val]) => {
    if (!val) return;
    return `${key}=${val}`;
  }).join('&')
};

//URLパラメータを取得しobjに格納
const UrlParamtoArray = () => Object.fromEntries([...new URLSearchParams(location.search)]);

//タグつきテンプレートリテラル
const tagT = (str, ...val) => {
  if (!val.every(v => (v === 0)||v)) return ''; // リテラル配列のどれかがfalsy(ただし0はtrue扱いにする)→何も出力しない
  return str.map((k = '', i) => {
    const v = (typeof val[i] === 'boolean' || !val[i]) ? '' : val[i];
    return k + v;
  }).join('');
};

const isWebview = [
  /-App-iOS/i, // アプリのUAにAppを付加
  /-App-Android/i,
].some(reg => window.navigator.userAgent.search(reg) >= 0); // webviewだったらtrue

const hideHeaderFooter = () => { // webviewだったらヘッダフッタ消す
  if (isWebview) {
    $('.headerFix').hide();
    $('.footer_menu').hide();
    $('article.content_all').css('top', 0);
  }
};

//パンくずリスト生成(簡易)未使用
const genSimpleBreadcrumbs = (sim) => {
  const simItemHref = {
    pad: ['190PadSIM X TOP', '../190sim/index.html'],
    kaimaku: ['開幕SIM TOP', 'plan_post_kaimaku.html'],
    sumahod: ['スマホ電話SIM TOP', 'plan_post_kaimaku.html']
  };
  const [href, link] = simItemHref[sim];
  const aTagArray = [
    '<a href="../index.html">b-mobile</a>',
    `<a href="${href}">${link}</a>`
  ];
  const liTag = aTagArray.map(tag => `<li>${tag}</li>`).join('');
  $(`.js-bread_crumbs`).html(`<ol>${liTag}</ol>`);
}

//指定場所までスクロール 引数は$オブジェクト指定
const onClickScrollPosition = ($selectorObj, adjust = 0) => {
  if ($selectorObj.length === 0) return; // $('')の指定は何もしない
  //const windowWidth = parseInt($(window).width());
  const pos = $selectorObj.offset().top;
  const scrollTo = pos - ($('#fixedBox').outerHeight(true) * 2) - adjust;
  const speed = (pos / 10) + 200;
  $('html,body').animate({scrollTop: scrollTo}, speed, 'swing');
};


// クリックされたタブに切り替え
// クリックするタブ部分ulに class="js-tab_area"をつける
// 切り替えるコンテンツ部分に  class="js-tabcontent_area"をつける
// クリックされた順番のところが連動して切り替わる
// indexはクリックされたタブliのインデックス
const tabSwitch = (e) => {
  const onloadContent = tabSwitchOnload || 'disp'; // tabSwitchOnload(呼出元で定義)未定義なら初期値'disp'
  const $content = $('.js-tabcontent_area');
  const $clickTab = (e.type === 'click') ? $(e.target).closest('li') : $('ul.js-tab_area li').eq(0); //e.targetはonでイベントが発生したオブジェクト。
  const index = (e.type === 'click') ? $clickTab.index() : 0; //クリックされたliのインデックス.load時は0
  const $targetContent = $content.eq(index); //クリックされたタブと同じ順番のコンテンツ

  $content.hide(); //コンテンツを一度すべて非表示
  if (e.type === 'load' && onloadContent === 'hide') { //load時呼出元で'hide'なら全て非表示にして早期リターン
    return false;
  }

  $clickTab.siblings().find('a').removeClass('active'); //タブについているクラスactiveを一度すべて消す

  if (e.type === 'click' && $(".border_b_blue").length) {
    const $tab_border = $(".border_b_blue");
    const border_color = (e.target.className.includes('marketbuy')) ? "#b9e6dc" : "#bbdefb"; //targetにmarketbuyのclassがあるか
    $tab_border.css("border-color", border_color);
  }
  $clickTab.find('a').addClass('active'); //クリックされたタブのみにクラスactiveをつける
  $targetContent.fadeIn("fast"); //クリックされたタブと同じ順番のコンテンツを表示
  onClickScrollPosition($targetContent); //コンテンツへスクロール

  setFlickity(index);
}

//classにsw-flickityがあれば、スマホ表示でflickityエレメントをセンターに
const setFlickity = (index) => {
  const flg_flickity = $(".sw-flickity").length;
  if (isSP && flg_flickity) {
    $(".sw-flickity").eq(index).flickity({
      "cellAlign": "center", //センター揃えはデフォルトなので不要
      "prevNextButtons": false
    });
  }
}

// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示
const switchDisplay = (selected, showItemName) => {
  selected = (typeof selected === 'undefined') ? 'unselected' : selected; // IEはデフォルト引数の記述が使えないので
  showItemName = (typeof showItemName === 'undefined') ? 'showitem' : showItemName; // IEはデフォルト引数の記述が使えないので
  $(`[data-${showItemName}]`).hide(); // data属性セレクタの書き方に要注意
  if (selected !== 'unselected') {
    const $showItem = $(`[data-${showItemName}="${selected}"]`); // data属性セレクタの書き方に要注意
    $showItem.fadeIn("fast");
    onClickScrollPosition($showItem);
  }
}

// 選択するトリガーの要素にdata-selected属性をつける。
// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示される
// data-selected属性とdata-showitem属性を対にすること
const selectSwitch = (e) => {
  const $selectbtn = $(e.target);
  const selectDataset = Object.keys(e.target.dataset); //押されたボタンのdatasetのキー名一覧
  const datasetName = selectDataset[0]; //押されたボタンのdataset名を取得
  const $selectbtns = ($selectbtn.siblings().length) ? $selectbtn.siblings() : $('[data-' + selectDataset + ']'); //同じdataset名を持つボタンか兄弟要素
  const selected = e.target.dataset.selected;

  $selectbtns.removeClass("btn_active btn_selectable").addClass("btn_unselect");
  $selectbtn.toggleClass("btn_active");
  switchDisplay(selected);
}

//googleカスタム検索のカスタマイズ
const init_googlesearchbox = () => {
  $('#cse-search-box input[type="text"]').attr('placeholder', 'b-mobileサイト内検索').css({
    'background': '',
    'text-indent': '0'
  });
};

const gen_scrollStopEvent = () => { // http://www.risewill.co.jp/blog/archives/2618
  const scrollStopEvent = new $.Event('scrollstop'); //スクロールストップを検知するjQueryイベントオブジェクトを生成
  let timer;
  const scrollStopEventTrigger = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {$(window).trigger(scrollStopEvent)}, 100);
  }
  $(window).on('scroll', scrollStopEventTrigger);
  $('body').on('touchmove', scrollStopEventTrigger);
};

// テーブルの高さを揃える 高さを揃える親要素にjs-heightAdjustをclass指定する
const heightAdjust = () => {
  if (!document.querySelector('.js-heightAdjust')) return;
  const tables = document.querySelectorAll('.js-heightAdjust table');
  if ((tables[1].offsetTop - tables[0].offsetTop) > tables[0].offsetHeight) return;

  const rows = tables[0].querySelectorAll('tbody tr');
  // 複数テーブルの各行の最大高さを格納する配列
  const maxHeights = [...rows].map((k, i) =>
    Math.max(...Array.from(tables, tb => tb.querySelectorAll('tbody tr')[i].offsetHeight))
  );

  tables.forEach(row => {
    const cells = row.querySelectorAll('tbody tr');
    cells.forEach((cell, index) => {
      cell.style.height = maxHeights[index] + 'px';
    });
  });
};

// 消費税・金額を税別→税込へ
const tax_disp = () => {
  const flg_intax = true; // 税別表記中はfalse
  const taxnote = (flg_intax) ? '特別な表記のない場合、価格は税込です' : '特別な表記のない場合、価格は税別です';
  $('.js-taxnote').text(taxnote);
  const taxrate = 1.10;
  $('.js-price_tax').each((i, elem) => {
    const price = Number(elem.textContent);
    const priceinTax = (flg_intax) ? Math.floor(price * taxrate) : price;
    const price_txt = String(priceinTax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円';
    elem.textContent = price_txt;
  });
};

// クリップボードにコピー
async function copyToClipboard (text, copy = '') {
  try {
    await navigator.clipboard.writeText(text);
    alert(`${copy}コピーしました`);
    return true;
  } catch (err) {
    try {
      //console.log('Async: Could not copy text: ', err);
      let input = document.createElement('input');
      document.body.appendChild(input);
      input.value = text;
      input.select();
      const result = document.execCommand('copy');
      document.body.removeChild(input);
      if (!result) {
        throw new Error('document.execCommand failed');
      }
      //console.log('document.execCommand: Copying to clipboard was successful!');
      alert(`${copy}コピーしました`);
      return true;
    } catch (err) {
      alert('コピーに失敗しました');
      //console.error('document.execCommand: Could not copy text: ', err);
      return false;
    }
  }
}

const imageInitload = () => { //レスポンシブ対応画像チェンジ
  const repl = isSP ? '_sp.' : '_pc.';
  document.querySelectorAll('.js-image-switch').forEach((elem) => {
    const arraySrc = elem.src.split('/');
    const filename = arraySrc.pop();
    const replaced = filename.replace(/(_sp|_pc)*\./g, repl);
    elem.src = arraySrc.join('/') + '/' + replaced;
  });
};

const gnaviInitload = () => {
  const menunames = {
    sp: ['プラン', '使える端末', 'サポート', 'お申込'],
    pc: ['料金・プラン', '使える端末', 'サポート', 'お申し込み'],
  };
  document.querySelectorAll('.globalmenu li a').forEach((elem, i) => {
    elem.text = (isSP) ? menunames.sp[i] : menunames.pc[i]; // メニューテキスト代入
  });
};

// https://tech.brick-plan.jp/responsive/responsive-pattern03/ での修正予定
window.addEventListener('resize', () => {
  const isSPLast = isSP;
  isSP = (window.innerWidth < 768);
  if (isSPLast ^ isSP === false) return; //^はXOR排他的論理和 true/falseが異なる組み合わせ=spとpcが入れ替わったときのみ以下処理
  clearTimeout(resizeTimer);
  const resizeTimer = setTimeout(() => {
    imageInitload(); //レスポンシブ対応画像チェンジ
    //gnaviInitload();
    footer_accordion({type: 'resize'});
  }, 100);
  return false;
});

window.addEventListener('load', () => {
  if (location.hash) { //ページ内リンク付きで遷移してきた場合(load内でないと、画面の高さ計算が終わらないため)
    onClickScrollPosition($(location.hash));
  }
});

document.addEventListener('DOMContentLoaded', () => {
  //gen_scrollStopEvent(); // スクロールを監視するパーツをなくすfromMF
  //init_oldipad();
  //init_googlesearchbox();
  //globalmenuCurrent(); //グローバルメニューハイライト
  //hilightCurrent(); //menu現在地ハイライト
  imageInitload();
  //gnaviInitload();
  replace_txtArrow();
  currentAutoSlide();
  heightAdjust();
  tax_disp();
  hideHeaderFooter();
  //changeHeaderBizlinkname(); // ヘッダーの法人へのリンクの名前変更

  //アコーディオンメニュー
  //e:ハンドリングイベント
  //elem:アコーディオンスイッチ部分。クリックされた要素
  //init:初期表示状態
  const accordion = ({type}, elem, init = 'open') => {
    const $aco_s = $(elem);
    const $content = $aco_s.next();
    const acomenu_title = ($aco_s.data('title') !== undefined) ? $aco_s.data('title') : "注意事項";

    if (type === "click") {
      const elemtxt = ($aco_s.text().slice(-3).indexOf('見る') < 0) ? '見る' : 'たたむ'; //見るになっていなかったら、「～を見る」にセット
      const toggleContent = new Promise((resolve, reject) => {
        $content.slideToggle('fast', () => {
          resolve(); // jqueryのslideToggleを終わった後にするcallbackにpromiseの終了としてresolveしておく
        });
      });
      toggleContent
        .then(() => $aco_s.text(`${acomenu_title}を${elemtxt}`)); // コンテンツの表示が切り替わった後に、テキストを変える
    }
  };

  // デフォルト、初期ロード時はコンテンツを開いた状態に
  [...document.querySelectorAll('.js-aco_switch, .js-aco_switch2')].forEach(elem => {
    elem.addEventListener('click', () => accordion({type: 'click'}, elem), false);
  });

  //フッターのアコーディオン
  const fotterItems = [...document.querySelectorAll('.footer_menu_toggle')];
  const footer_accordion = ({type}, elem) => { // type:イベントタイプ
    const funcToggle = (kind) => {
      fotterItems.forEach(elem => {
        $(elem).next()[kind]();
      });
    };
    if (!isSP) {
      funcToggle('show');
      return; // PCはload時でもリサイズ時でも開いておく
    }
    if (type === 'load' || type === 'resize') { // ロード時・リサイズ時、フッタは閉じる
      funcToggle('hide');
    }
    if (type === 'click') {
      $(elem).find('span').toggleClass('active');
      $(elem).next().slideToggle('fast');
    }
  };

  footer_accordion({type: 'load'}); // スマホの場合、初期ロード時、フッタは閉じておく
  fotterItems.forEach(elem => { // 複数の要素にイベントリスナを付与
    elem.addEventListener('click', () => footer_accordion({type: 'click'}, elem), false);
  });

  const closeBtn = ({type}, elem) => { // 未使用
    const $btn = $(elem);
    $btn.prevAll('#flow_aco dd').slideToggle('fast');
    $btn.toggleClass("active"); //追加部分
    $btn.prev().slideToggle('fast');
  };
  [...document.querySelectorAll('.close_btn')].forEach(elem => {
    elem.addEventListener('click', () => closeBtn({type: 'click'}, elem), false);
  });

  if (elemExist('ul.js-tab_area')) tabSwitch({type: 'load'}); // デフォルト、初期ロード時はコンテンツを開いた状態に
  $('ul.js-tab_area').on('click', 'li', tabSwitch); //第2引数のセレクタ指定でクリックされた要素をtabswitchに渡す

  let fixedObj = {
    toTop: {elem: 'toTopButton', posY: 400, className: 'toTopButton-visible',},
    header: {elem: 'fixedBox', posY: 500, className: 'fixed',},
  };
  //下のforeachで未使用
  //if (elemExist('#js-pageNavi')) fixedObj.pageNavi = {elem: 'js-pageNavi', posY: 500, className: 'page_nav_fixed',};

  const dispElem = ({elem, posY, className}) => {
    const $elem = document.getElementById(elem);
    (window.scrollY > posY) ? $elem.classList.add(className) : $elem.classList.remove(className); // toggleで行いたい
  };
  //$(window).on('scrollstop', () => { // スクロールが一時停止したら→スクロールのパーツをなくすfromMF
  //  const elemFixed = document.getElementById('fixedBox');
  //  //const isShown = (window.getComputedStyle(elemFixed).getPropertyValue('position') === 'fixed');
  //  Object.values(fixedObj).forEach(el => {
  //    dispElem(el); // スクロール位置によって要素を表示・非表示
  //  });
  //});

  // 外部リンク計測
  const captureOuterLink = ({type}, elem) => {
    const {href} = elem;
    const {dir, filename} = URLdiv(); // イベント発生ページ
    gtmDL('outbaund', 'outbaund', `${dir}${filename}`, href);
  };
  const elemAtags = [...document.getElementsByTagName('a')];
  elemAtags.filter(({href}) => (!href.includes(location.host))).
  forEach(elem => {
    elem.addEventListener('click', () => captureOuterLink({type: 'click'}, elem));
  });

  //ページ内アンカーを押したときにスムーズスクロール
  [...document.querySelectorAll("a[href^='#']")].forEach(elem => {
    const {hash} = elem;
    const $target = ($(hash).length) ? $(hash) : $(`a[name^=${hash}`);
    elem.addEventListener('click', () => onClickScrollPosition($target));
  });

  // TOPに戻るアニメーション
  $('#toTopButton').on('click', () => {
    $('html').animate({scrollTop: 0}, 'slow');
  });

  //タブ切り替え URLハッシュによって切り替え // URLハッシュで切り替える機能は後日実装 7/26
  let hash = location.hash; //#以降のハッシュ取得
  //hashの中に#tab～が存在するか調べて連想配列に
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  //hashに要素が存在する場合、hashで取得した文字列（#tab2,#tab3等）から#より後を取得(tab2,tab3)
  const index = ($(hash).length) ? hash.slice(1) : 0;
});

export {
  gtmDL,
  isSP,
  isiOS,
  isWebview,
  replace_txtArrow,
  putComma,
  calcTax,
  currentAutoSlide,
  elemExist,
  URLdiv,
  getUrlDir,
  getHtmlName,
  getUrlParam,
  strUrlParam,
  UrlParamtoArray,
  tagT,
  genSimpleBreadcrumbs,
  onClickScrollPosition,
  tabSwitch,
  setFlickity,
  switchDisplay,
  selectSwitch,
  //init_oldipad,
  //init_googlesearchbox,
  //gen_scrollStopEvent,
  heightAdjust,
  tax_disp,
  imageInitload,
  copyToClipboard,
};

window.gtmDL = gtmDL;
window.isSP = isSP;
window.isiOS = isiOS;
window.isWebview = isWebview;
window.putComma = putComma;
window.calcTax = calcTax;
window.elemExist = elemExist;
window.URLdiv = URLdiv;
window.getUrlDir = getUrlDir;
window.getHtmlName = getHtmlName;
window.getUrlParam = getUrlParam;
window.strUrlParam = strUrlParam;
window.UrlParamtoArray = UrlParamtoArray;
window.tagT = tagT;
window.genSimpleBreadcrumbs = genSimpleBreadcrumbs;
window.onClickScrollPosition = onClickScrollPosition;
window.tabSwitch = tabSwitch;
window.setFlickity = setFlickity;
window.switchDisplay = switchDisplay;
window.selectSwitch = selectSwitch;
window.copyToClipboard = copyToClipboard;
